<template>
  <div class="main" data-title="所有员工列表" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">员工列表</h2>
      </div>
      <div class="head-search"></div>
      <div class="head-tabs">
        <div @click="$router.push({name: 'company-department'})" class="tab">部门列表</div>
        <div @click="$router.push({name: 'company-employee'})" class="tab curr">员工列表</div>
      </div>
    </div>
    <el-card class="main-content" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">员工信息</div>
        <div class="jue-card-btns">
          <el-button @click="showEdit" class="btn" icon="el-icon-edit" type="primary" v-has="'muser-create'">新建</el-button>
          <!-- <el-dropdown>
            <el-button class="btn" plain>
              批量操作
              <i class="el-icon-arrow-down"></i>
            </el-button>
            
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>初始化密码</el-dropdown-item>
              <el-dropdown-item>导出Excel</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>-->

          <el-input
            class="input-with-select"
            placeholder="员工姓名/电话号码"
            style="margin-left: 20px; width: 300px"
            v-model="key.search_key"
          >
            <el-select
              @change="getList"
              placeholder="请选择"
              slot="prepend"
              style="width: 80px"
              v-model="key.status"
            >
              <el-option :value="1" label="正常"></el-option>
              <el-option :value="2" label="锁定"></el-option>
            </el-select>
          </el-input>
        </div>
      </div>

      <div class="table-wrapper">
        <el-table :data="eployeeData" :style="'width: '+$store.state.tableWidth" class="table">
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column label="姓名" prop="realname" show-overflow-tooltip></el-table-column>
          <el-table-column label="手机" prop="mobile" show-overflow-tooltip></el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">
                <i class="el-icon-unlock"></i>
              </span>
              <span style="color: #f56c6c;" v-if="scope.row.status == 2">
                <i class="el-icon-lock"></i>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="工号" prop="code" show-overflow-tooltip></el-table-column>
          <el-table-column label="性别" prop="sex" show-overflow-tooltip>
            <template slot-scope="scope">{{scope.row.sex == '1' ? '男' : '女'}}</template>
          </el-table-column>
          <el-table-column label="入职日期" prop="join_date" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column label="部门" prop="department" show-overflow-tooltip></el-table-column> -->
          <el-table-column label="角色" show-overflow-tooltip>
            <template slot-scope="scope">{{getRole(scope.row.group_id).label}}</template>
          </el-table-column>
          <!-- <el-table-column label="籍贯" prop="native_place" show-overflow-tooltip>
            <template slot-scope="scope">{{getCityText(scope.row.native_place)}}</template>
          </el-table-column> -->
          <el-table-column label="身份证" prop="idcard"></el-table-column>
          <el-table-column label="备注" prop="memo" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="100px">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" size="small" type="text">修改</el-button>
              <span class="jue-divider"></span>
              <el-button
                @click="deleteItem(scope.$index, scope.row)"
                class="btn-delete no-bg"
                size="small"
                type="text"
                v-if="scope.row.status == 1"
                v-has="'muser-lock'"
              >锁定</el-button>

              <el-button
                @click="deleteItem(scope.$index, scope.row)"
                size="small"
                type="text"
                v-if="scope.row.status == 2"
                v-has="'muser-lock'"
              >解锁</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination
          :page-size="key.count"
          :total="page.total"
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </el-card>
    <employee-edit @getEmployeeData="getEmployeeData" ref="employeeEdit"></employee-edit>
  </div>
</template>
<script>
import EmployeeEdit from '@/components/company/EmployeeEdit.vue'
import { roleByID } from '@/unit/unit.js'
import { CodeToText, TextToCode } from 'element-china-area-data'

export default {
  components: {
    EmployeeEdit
  },
  data() {
    return {
      visible: false,
      base: {
        text: ''
      },
      eployeeData: [],
      key: {
        search_key: '',
        status: 1,
        page: 1,
        count: 15
      },
      page: {
        total: 0
      }
    }
  },
  watch: {
    'key.search_key': function() {
      setTimeout(() => {
        this.key.page = 1
        this.getEmployeeData()
      }, 100)
    }
  },
  mounted() {
    this.getEmployeeData()
  },
  methods: {
    getList() {
      this.key.page = 1
      this.getEmployeeData()
    },
    handleCurrentChange(val) {
      this.key.page = val
      this.getEmployeeData()
    },
    getRole(id) {
      return roleByID(id)
    },
    getCityText(code) {
      if (code == '') {
        return ''
      }

      let arr = JSON.parse(code)
      return CodeToText[arr[0]] + ' / ' + CodeToText[arr[1]]
    },
    showEdit() {
      this.$refs.employeeEdit.addData()
    },
    getEmployeeData() {
      this.$axios.post('/muser/lists', this.key).then(res => {
        if (res.data.code == 200) {
          //更新用户列表
          this.eployeeData = res.data.data.list
          this.page.total = res.data.data.total
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    edit(item) {
      this.$refs.employeeEdit.editData(item)
    },
    deleteItem(index, item) {
      let num = item.status == 1 ? 2 : 1
      let text = item.status == 1 ? '锁定' : '解锁'
      this.$confirm(
        '此操作将' + text + ' ' + item.realname + ' , 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$axios
            .post('/muser/lock', {
              uid: item.uid,
              status: num
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.getEmployeeData()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
}

.footer {
  text-align: right;
  padding: 20px 0;
}
</style>