<template>
  <el-drawer :visible.sync="visible" :with-header="false" size="600px">
    <div class="jue-drawer-header">
      <div class="jue-drawer-title">编辑员工</div>
      <div class="jue-drawer-btns">
        <i @click="visible = false" class="el-icon-close"></i>
      </div>
    </div>

    <div class="jue-drawer-body">
      <el-form
        :model="form"
        :rules="rules"
        class="form"
        label-position="right"
        label-width="120px"
        ref="form"
      >
        <el-form-item label="姓名: " prop="realname">
          <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.realname"></el-input>
        </el-form-item>

        <el-form-item label="手机: " prop="mobile">
          <el-input placeholder="请输入" suffix-icon="el-icon-mobile-phone" v-model="form.mobile">
            <!-- <template slot="prepend">+86</template> -->
          </el-input>
        </el-form-item>
        <el-form-item label="是否销售顾问: " prop="mobile">
          <el-radio :label="1" v-model="form.is_seller">是</el-radio>
          <el-radio :label="2" v-model="form.is_seller">否</el-radio>
        </el-form-item>

        <el-form-item label="工号: ">
          <el-input placeholder="请输入" suffix-icon="el-icon-user" v-model="form.code"></el-input>
        </el-form-item>

        <el-form-item label="身份证: " prop="idcard">
          <el-input placeholder="请输入" suffix-icon="el-icon-postcard" v-model="form.idcard"></el-input>
        </el-form-item>

        <el-form-item label="性别: ">
          <el-radio :label="1" v-model="form.sex">男</el-radio>
          <el-radio :label="2" v-model="form.sex">女</el-radio>
        </el-form-item>

        <el-form-item label="入职日期: ">
          <el-date-picker
            @change="$forceUpdate()"
            class="w100"
            format="yyyy 年 MM 月 dd 日"
            placeholder="请选择"
            suffix-icon="el-icon-date"
            type="date"
            v-model="form.join_date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="角色: ">
          <el-select class="w100" placeholder="请选择" v-model="form.group_id">
            <el-option
              :key="index"
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in $store.state.role"
              v-show="item.value != 1"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="籍贯: ">
          <el-cascader :options="options" class="w100" v-model="form.tmp_native_place"></el-cascader>
        </el-form-item>-->

        <el-form-item label="备注: ">
          <el-input placeholder="请输入" show-word-limit type="textarea" v-model="form.memo"></el-input>
        </el-form-item>
        <el-form-item label="下属员工: ">
          <el-checkbox-group v-model="form.children_uid">
            <el-checkbox v-show="item[3] == 1" :label=key :key="key" v-for="(item, key) in $store.state.userList">{{item[0]}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </div>

    <div class="form-footer">
      <div>
        <el-button @click="resetForm('form')">取 消</el-button>
        <el-button @click="submitForm('form')" type="primary">提 交</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { provinceAndCityData } from 'element-china-area-data'
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      } else {
        const reg = /^1\d{10}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号码'))
        }
      }
    }

    var checkIdCard = (rule, id, callback) => {
      if (id == '') {
        callback()
      }

      var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/
      //号码规则校验
      if (!format.test(id)) {
        return callback(new Error('身份证号码不合规'))
      }
      //区位码校验
      //出生年月日校验   前正则限制起始年份为1900;
      var year = id.substr(6, 4), //身份证年
        month = id.substr(10, 2), //身份证月
        date = id.substr(12, 2), //身份证日
        time = Date.parse(month + '-' + date + '-' + year), //身份证日期时间戳date
        now_time = Date.parse(new Date()), //当前时间戳
        dates = new Date(year, month, 0).getDate() //身份证当月天数
      if (time > now_time || date > dates) {
        return callback(new Error('出生日期不合规'))
      }
      //校验码判断
      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2) //系数
      var b = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2') //校验码对照表
      var id_array = id.split('')
      var sum = 0
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k])
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return callback(new Error('身份证校验码不合规'))
      }
      callback()
    }

    return {
      visible: false,
      options: provinceAndCityData,
      base: {
        type: 'add', // add | edit
      },
      rules: {
        realname: [{ required: true, message: '必需填写', trigger: 'blur' }],
        mobile: [{ required: true, validator: checkMobile, trigger: 'blur' }],
        idcard: [{ validator: checkIdCard, trigger: 'blur' }],
      },
      form: {
        realname: '',
        mobile: '',
        code: '',
        idcard: '',
        is_seller: 1,
        sex: 1,
        join_date: '',
        group_id: '',
        memo: '',
        children_uid: [],
        // native_place: '',
        // tmp_native_place: '',
      },
    }
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.visible = false
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url =
            this.base.type == 'add' ? '/muser/create' : '/muser/update_profile'
          this.$axios.post(url, this.form).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              //更新用户列表
              this.$emit('getEmployeeData')
              this.visible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },

    //添加
    addData() {
      this.base.type = 'add'
      this.form = {
        realname: '',
        mobile: '',
        code: '',
        idcard: '',
        is_seller: 1,
        sex: 1,
        join_date: '',
        group_id: '',
        memo: '',
        children_uid: [],
        // native_place: '',
        // tmp_native_place: ''
      }
      this.visible = true
    },

    //设置编辑初始数据
    editData(data) {
      console.log(data)
      this.base.type = 'edit'

      this.form.realname = data.realname
      this.form.mobile = data.mobile
      this.form.code = data.code
      this.form.idcard = data.idcard
      this.form.join_date = data.join_date
      this.form.is_seller = data.is_seller
      this.form.group_id = data.group_id.toString()
      // this.$set(this.form, 'join_date', data.join_date)
      this.form.memo = data.memo
      // this.form.native_place = data.native_place
      this.form.sex = data.sex
      // this.form.role = data.role.toString()
      this.form.uid = data.uid
      this.visible = true
      this.form.children_uid = data.children_uid.split('|')
      // if (data.native_place != '') {
      //   this.form.tmp_native_place = JSON.parse(data.native_place)
      // }

      //获取用户详细
    },
  },
  // watch: {
  //   'form.tmp_native_place': function() {
  //     this.form.native_place = JSON.stringify(this.form.tmp_native_place)
  //   }
  // }
}
</script>
<style lang="less" scoped>
.jue-drawer-body {
  padding: 40px 40px 0;
}

.w100 {
  width: 100%;
}

.form-footer {
  text-align: right;
  width: 600px;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}

.list {
  display: flex;
  .name {
    color: #409eff;
    flex: 1;
  }
}

/deep/ .el-tag {
  margin-right: 10px;
}
</style>